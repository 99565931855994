<template>
  <v-container class="h-screen">
    <!--    <div class="position-relative h-100">-->
    <!--      <transition name="burger" mode="out-in" :duration="1000">-->
    <!--        <div-->
    <!--          v-if="showBurger"-->
    <!--          class="burger-wrapper cursor-pointer position-absolute"-->
    <!--          style="top: 30px; right: 20px; z-index: 2000"-->
    <!--          role="button"-->
    <!--          @click="showBurger = false"-->
    <!--        >-->
    <!--          <div class="b-before"></div>-->
    <!--          <div class="b-after"></div>-->
    <!--        </div>-->

    <!--        <div-->
    <!--          v-else-if="!showBurger"-->
    <!--          style="height: 60px; width: 60px; z-index: 2000"-->
    <!--          class="d-flex justify-center align-center"-->
    <!--          @click="showBurger = true"-->
    <!--        >-->
    <!--          <div-->
    <!--            role="button"-->
    <!--            class="close-icon cursor-pointer position-absolute"-->
    <!--            style="top: 20px; right: 27px; z-index: 2000"-->
    <!--          >-->
    <!--            <label class="c-before"></label>-->
    <!--            <label class="c-after"></label>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </transition>-->
    <div class="position-relative h-screen w-100">
      <transition name="back">
        <div v-if="showBurger" class="background"></div>
      </transition>
      ShowBurger:{{ showBurger }}
      <v-btn
        position="absolute"
        class="ma-10"
        style="top: 100px; right: 100px; z-index: 2000"
        @click="showBurger = !showBurger"
        text="Show"
      ></v-btn>
    </div>

    <!--    </div>-->
  </v-container>
</template>

<script setup>
import { ref } from "vue";

const showBurger = ref(false);
</script>

<style scoped>
.background {
  will-change: transform;
  position: fixed;
  top: calc(3vw + 25px);
  right: calc(5vw + 24px);
  height: calc((400vw + 400vh) / 2);
  width: calc((400vw + 400vh) / 2);
  background-color: mediumpurple;
  border-radius: 50%;
  transform: translate(50%, -50%) scale(1);
  z-index: 1900;
}

.back-enter-from {
  transform: translate(50%, -50%) scale(0);
}

.back-leave-to {
  transform: translate(50%, -50%) scale(0);
}

.back-enter-active,
.back-leave-active {
  transition: transform 600ms ease-in-out;
}

.close-icon {
  display: flex;
  transform: rotate(45deg);
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  cursor: pointer;
}
.c-before {
  transform-origin: top;
  transform: scale(1);
  position: absolute;
  background-color: #dc012e;
  height: 50px;
  width: 4px;
}
.c-after {
  transform-origin: right;
  transform: scale(1);
  position: absolute;
  background-color: #dc012e;
  height: 4px;
  width: 50px;
}

.burger-enter-from .c-before {
  transform: scaleY(0);
  transform-origin: bottom;
}

.burger-enter-from .c-after {
  transform: scaleX(0);
  transform-origin: left;
}

.burger-enter-to .c-before {
  transform-origin: bottom;
  transform: scaleY(1);
}
.burger-enter-to .c-after {
  transform: scaleX(1);
  transform-origin: left;
}

.burger-leave-from .c-before {
  transform-origin: top;
  transform: scaleY(1);
}

.burger-leave-from .c-after {
  transform: scaleX(1);
  transform-origin: right;
}

.burger-leave-to .c-before {
  transform-origin: top;
  transform: scaleY(0);
}
.burger-leave-to .c-after {
  transform-origin: right;
  transform: scaleX(0);
}

.burger-enter-active .c-before {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-enter-active .c-after {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;
}

.burger-leave-active .c-before {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 400ms;
}

.burger-leave-active .c-after {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 500ms;
}
</style>

<template>
  <v-row
    justify="center"
    align="start"
    no-gutters
    id="B"
    class="h-screen pt-10"
    style="margin-top: 200px"
  >
    <v-col cols="12" md="12">
      <v-row no-gutters align="end">
        <v-col style="flex-grow: 14" class="ma-2">
          <v-row no-gutters>
            <v-col class="pt-3" style="flex-grow: 0">
              <v-img
                :aspect-ratio="1"
                :width="getFixedImg"
                :src="commas"
              ></v-img>
            </v-col>
            <v-col
              class="d-none d-md-block text-h5 text-md-h4 text-lg-h3 ps-5 font-weight-medium"
            >
              Opportunities don’t happen, you
              <span class="font-weight-semi-bold font-italic">create</span>
              them.
              <br />
              <span class="text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4">
                Chris Grosser
              </span>
            </v-col>
            <v-col
              class="d-block d-md-none text-h5 text-md-h4 text-lg-h3 ps-5 font-weight-medium"
              style="line-height: 2.2rem"
            >
              Opportunities
              <br />
              don’t happen,
              <br />
              you
              <span class="font-weight-semi-bold font-italic">create</span>
              them.
              <br />
              <span class="text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4">
                Chris Grosser
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters></v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mt-md-8"></v-divider>
      <v-row no-gutters align="center">
        <v-col
          class="text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 font-weight-bold py-8 py-md-5"
        >
          MISSION STATEMENT
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 line-h">
          At YMC Partners, we redefine financial landscapes with innovative
          solutions that create lasting value. We are committed to structuring
          impactful deals and unlocking strategic opportunities, empowering our
          elite clientele to achieve unparalleled success. Through our
          expertise, integrity, and commitment to excellence, we navigate the
          complexities of global financial markets, driving sustainable growth.
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="11" md="12" class="bg-orange-darken-2 mx-auto"></v-col>
  </v-row>
</template>

<script setup>
import commas from "@/assets/commas.svg";
import { useDisplay } from "vuetify";
import { computed } from "vue";

defineOptions({ name: "OurMissionView" });

const { name } = useDisplay();

const getFixedImg = computed(() => {
  let result = 0;
  switch (name.value) {
    case "xs":
      result = 35;
      break;
    case "sm":
      result = 35;
      break;
    case "md":
      result = 75;
      break;
    case "lg":
      result = 100;
      break;
    case "xl":
      result = 100;
      break;
    case "xxl":
      result = 100;
      break;
  }
  return result;
});
</script>

<style scoped></style>

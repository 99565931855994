<template>
  <v-app>
    <router-view />
    <VideoView></VideoView>
  </v-app>
</template>

<script>
import { defineComponent } from "vue";
import VideoView from "@/views/VideoView/index.vue";

export default defineComponent({
  components: { VideoView },
});
</script>

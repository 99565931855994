import { createRouter, createWebHistory } from "vue-router";

import MainView from "@/views/MainView/index.vue";
import TestView from "@/views/TestView.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: MainView,
  },
  {
    path: "/test",
    name: "test",
    component: TestView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () =>
  //     import( "../views/IndexView.vue"),
  // },
];

// const router = new VueRouter({
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition;
//     }
//     if (to.hash) {
//       return { selector: to.hash }; // <==== the important part
//     }
//     return { x: 0, y: 0 };
//   },
// });

const router = createRouter({
  // eslint-disable-next-line no-undef
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    return { x: 0, y: 0 };
  },
});

export default router;

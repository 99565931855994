<template>
  <v-sheet
    v-show="!isHiddenMenu"
    color="transparent"
    class="position-fixed w-100 h-screen"
    style="top: 0; left: 0; z-index: 2000"
  >
    <v-layout class="d-flex flex-column">
      <!--    <v-row no-gutters class="flex-column h-100 w-100">-->
      <!--      <v-col>-->
      <v-row
        style="height: 200px; width: 100%"
        no-gutters
        justify="space-between"
        class="flex-column"
      ></v-row>

      <v-list
        bg-color="transparent"
        class="text-center overflow-hidden cursor-pointer"
        lines="two"
        style="position: relative; z-index: 2000"
        @mousemove="onMouseMove"
      >
        <v-list-item
          active-class="font-italic"
          class="text-white text-h5 text-md-h4 ps-10 ps-md-0 zindx semibold"
        >
          <v-row no-gutters>
            <v-col md="6"></v-col>
            <transition name="a" @after-leave="hidingMenu">
              <v-col
                v-if="show"
                cols="12"
                md="6"
                class="text-start menu-list"
                @click="goToHome"
              >
                Home
              </v-col>
            </transition>
          </v-row>
        </v-list-item>
        <v-list-item
          active-class="font-italic"
          class="text-white text-h5 text-md-h4 ps-10 ps-md-0 zindx semibold"
        >
          <v-row no-gutters>
            <v-col md="6"></v-col>
            <transition name="b">
              <v-col
                v-if="show"
                cols="12"
                md="6"
                class="text-start menu-list"
                @click="goToOurMission"
              >
                Our mission
              </v-col>
            </transition>
            <!--            <v-btn :to="{ name: 'home', hash: '#our_mission' }">Goto</v-btn>-->
          </v-row>
        </v-list-item>
        <v-list-item
          active-class="font-italic"
          class="text-white text-h5 text-md-h4 ps-10 ps-md-0 zindx semibold"
        >
          <v-row no-gutters>
            <v-col md="6"></v-col>
            <transition name="c">
              <v-col
                v-if="show"
                cols="12"
                md="6"
                class="text-start menu-list"
                @click="goToOurPeople"
              >
                Our people
              </v-col>
            </transition>
          </v-row>
        </v-list-item>
        <v-list-item
          active-class="font-italic"
          class="text-white text-h5 text-md-h4 ps-10 ps-md-0 zindx semibold"
        >
          <v-row no-gutters>
            <v-col md="6"></v-col>
            <transition name="d">
              <v-col
                v-if="show"
                cols="12"
                md="6"
                class="text-start menu-list"
                @click="goToContact"
              >
                Contact
              </v-col>
            </transition>
          </v-row>
        </v-list-item>
        <transition name="magic">
          <div
            v-if="show"
            :style="{
              zIndex: '2001',
              height: '75px',
              position: 'absolute',
              top: `${magic_y}px`,
            }"
            class="magic-line ps-10 ps-md-0 d-none d-sm-block"
          ></div>
        </transition>
      </v-list>
      <v-row no-gutters style="margin-top: 5rem">
        <v-col md="6"></v-col>
        <transition name="mail">
          <v-col
            v-if="show"
            cols="12"
            md="6"
            id="email"
            class="text-start text-white cursor-pointer ps-10 ps-md-0"
          >
            <a
              class="font-weight-regular text-white text-decoration-none position-relative"
              style="font-size: 1.25rem"
              href="mailto:team@ymc.partners"
            >
              team@ymc.partners
            </a>
          </v-col>
        </transition>
      </v-row>
      <!--      </v-col>-->
      <!--    </v-row>-->
    </v-layout>
  </v-sheet>
</template>

<script setup>
import { inject, onBeforeUnmount, ref, watch } from "vue";

defineOptions({ name: "BurgerMenu" });

const props = defineProps({
  show: { type: Boolean, default: false },
});
const emit = defineEmits(["onClose"]);

const scaling = ref(false);
const magic_y = ref(8);
const mainViewYinjected = inject("MainViewYCoordinate");
const Ycoordinate = ref(null);
const isHiddenMenu = ref(true);

watch(mainViewYinjected, (newValue) => {
  Ycoordinate.value = newValue;
});

const onMouseMove = (event) => {
  let result = null;
  // console.log(event.clientY);
  if (event.clientY > 220 && event.clientY < 309) {
    result = 8;
  } else if (event.clientY > 310 && event.clientY < 381) {
    result = 80;
  } else if (event.clientY > 382 && event.clientY < 451) {
    result = 150;
  } else if (event.clientY > 452 && event.clientY < 493) {
    result = 220;
  } else if (event.clientY > 480) {
    result = returnCurrentPage();
  } else if (event.clientY < 220) {
    result = returnCurrentPage();
  }

  magic_y.value = result;
};

const returnCurrentPage = () => {
  let result = null;
  if (Ycoordinate.value <= 1043) {
    console.log("Home");
    result = 8;
  } else if (Ycoordinate.value >= 1044 && Ycoordinate.value <= 2087) {
    console.log("Mission");
    result = 80;
  } else if (Ycoordinate.value >= 2088 && Ycoordinate.value <= 3131) {
    console.log("Peoples");
    result = 150;
  } else if (Ycoordinate.value >= 3132) {
    console.log("Contact");
    result = 220;
  }
  magic_y.value = result;
  return result;
};

const goToHome = () => {
  emit("onClose");
  setTimeout(() => scrollTo("A", 0), 2000);
};

const goToOurMission = () => {
  emit("onClose");
  setTimeout(() => scrollTo("B", 0), 2000);
};
const goToOurPeople = () => {
  emit("onClose");
  setTimeout(() => scrollTo("C", 0), 2000);
};
const goToContact = () => {
  emit("onClose");
  setTimeout(() => scrollTo("D", 0), 2000);
};

watch(
  () => props.show,
  (newValue) => {
    returnCurrentPage();
    if (newValue == true) {
      isHiddenMenu.value = false;
    }
  },
  { immediate: true },
);

const hidingMenu = () => {
  isHiddenMenu.value = true;
};

const scrollTo = (el, offset) => {
  let ref = document.getElementById(el);

  window.scroll({ top: ref.offsetTop - offset, left: 0, behavior: "smooth" });
};

onBeforeUnmount(() => {
  scaling.value = false;
});
</script>

<style scoped>
.zindx {
  z-index: 2005;
}
.zindx:hover {
  font-weight: 500;
  font-style: italic;
}
.semibold {
  font-weight: 600;
}

.burger-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  height: 60px;
  width: 60px;
  cursor: pointer;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
}

.b-before,
.b-after {
  transform-origin: right;
  height: 4px;
  width: 60px;
  background-color: #dc012e;
  transform: scaleX(1);
}

.burger-enter-from .b-before,
.burger-enter-from .b-after {
  transform: scaleX(0);
}

.burger-enter-to .b-before,
.burger-enter-to .b-after {
  transform: scaleX(1);
}

.burger-leave-from .b-before,
.burger-leave-from .b-after {
  transform: scaleX(1);
}

.burger-leave-to .b-before,
.burger-leave-to .b-after {
  transform: scaleX(0);
}

.burger-enter-active .b-before {
  transform-origin: left;
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;
}

.burger-enter-active .b-after {
  transform-origin: left;
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-leave-active .b-before {
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-leave-active .b-after {
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;
}

.close-icon {
  display: flex;
  transform: rotate(45deg);
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  cursor: pointer;
}
.c-before {
  transform-origin: top;
  transform: scale(1);
  position: absolute;
  background-color: #dc012e;
  height: 50px;
  width: 4px;
}
.c-after {
  transform-origin: right;
  transform: scale(1);
  position: absolute;
  background-color: #dc012e;
  height: 4px;
  width: 50px;
}

.burger-enter-from .c-before {
  transform: scaleY(0);
  transform-origin: bottom;
}

.burger-enter-from .c-after {
  transform: scaleX(0);
  transform-origin: left;
}

.burger-enter-to .c-before {
  transform-origin: bottom;
  transform: scaleY(1);
}
.burger-enter-to .c-after {
  transform: scaleX(1);
  transform-origin: left;
}

.burger-leave-from .c-before {
  transform-origin: top;
  transform: scaleY(1);
}

.burger-leave-from .c-after {
  transform: scaleX(1);
  transform-origin: right;
}

.burger-leave-to .c-before {
  transform-origin: top;
  transform: scaleY(0);
}
.burger-leave-to .c-after {
  transform-origin: right;
  transform: scaleX(0);
}

.burger-enter-active .c-before {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-enter-active .c-after {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;
}

.burger-leave-active .c-before {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 400ms;
}

.burger-leave-active .c-after {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 500ms;
}

.menu-list {
  transform: translateY(0%);
}

.a-enter-from,
.b-enter-from,
.c-enter-from,
.d-enter-from {
  transform: translateY(100%);
}
.a-enter-to,
.b-enter-to,
.c-enter-to,
.d-enter-to {
  transform: translateY(0%);
}

.a-leave-from,
.b-leave-from,
.c-leave-from,
.d-leave-from {
  transform: translateY(0%);
}

.a-leave-to,
.b-leave-to,
.c-leave-to,
.d-leave-to {
  transform: translateY(100%);
}

.a-enter-active {
  transition: transform 1000ms ease 500ms;
}
.b-enter-active {
  transition: transform 1000ms ease 600ms;
}
.c-enter-active {
  transition: transform 1000ms ease 700ms;
}
.d-enter-active {
  transition: transform 1000ms ease 800ms;
}

.a-leave-active {
  transition: transform 1000ms ease 400ms;
}
.b-leave-active {
  transition: transform 1000ms ease 300ms;
}
.c-leave-active {
  transition: transform 1000ms ease 200ms;
}
.d-leave-active {
  transition: transform 1000ms ease 100ms;
}

.mail-enter-from {
  transform: translateY(100%);
}
.mail-enter-to {
  transform: translateY(0);
}
.mail-leave-from {
  transform: translateY(0);
}
.mail-leave-to {
  transform: translateY(100%);
}
.mail-enter-active {
  transition: transform 1000ms ease 900ms;
}
.mail.a-leave-active {
  transition: transform 1000ms ease;
}

.magic-line {
  width: 100%;
  transform: scaleX(1);
  background-color: var(--h-global-color-menu-hover);
  transition: top 500ms ease;
}
.magic-enter-from {
  transform: scaleX(0);
  transform-origin: left;
}
.magic-enter-to {
  transform: scaleX(1);
  transform-origin: left;
}
.magic-leave-from {
  transform-origin: right;
  transform: scaleX(1);
}
.magic-leave-to {
  transform-origin: right;
  transform: scaleX(0);
}

.magic-enter-active {
  transition: transform 500ms cubic-bezier(0.645, 0.045, 0.355, 1) 1000ms;
}
.magic-leave-active {
  transition: transform 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
</style>

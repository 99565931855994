<template>
  <v-container>
    <v-row
      style="height: 200px; width: 100%; z-index: 2100"
      no-gutters
      align="center"
      justify="space-between"
    >
      <v-col>
        <v-img
          :src="isMenuOpenedInstant ? logoLight : logo"
          class="cursor-pointer mt-1"
          :aspect-ratio="1"
          @click="goToHome"
          :width="mobile ? 100 : 160"
          contain
          style="color: #fff"
        />
      </v-col>
      <div class="cursor-pointer">
        <transition name="burger" mode="out-in" :duration="1000">
          <div
            v-if="!isMenuOpenedInstant"
            class="burger-wrapper cursor-pointer"
            style="z-index: 2100"
            role="button"
            @click="
              () => {
                emit('openMenuDialog');
              }
            "
          >
            <div class="b-before cursor-pointer"></div>
            <div class="b-after cursor-pointer"></div>
          </div>

          <div
            v-else-if="isMenuOpenedInstant"
            style="height: 60px; width: 60px; z-index: 2100"
            class="d-flex justify-center align-center"
            @click="
              () => {
                emit('closeMenuDialog');
              }
            "
          >
            <div
              role="button"
              class="close-icon cursor-pointer"
              style="z-index: 5000"
            >
              <label class="c-before cursor-pointer"></label>
              <label class="c-after cursor-pointer"></label>
            </div>
          </div>
        </transition>
      </div>
    </v-row>
  </v-container>
</template>

<script setup>
import logo from "@/assets/logo.svg";
import logoLight from "@/assets/logo_light.svg";
import { useDisplay } from "vuetify";
import { ref, watch } from "vue";

const emit = defineEmits(["openMenuDialog", "closeMenuDialog"]);
defineOptions({ name: "MainHeader" });
const props = defineProps({
  isMenuOpenedInstant: { type: Boolean, default: false },
});
const show_menu = ref(false);
const show_background = ref(false);
const show_menu_modal = ref(false);
const showMenuModalNoDelay = ref(false);

const { mobile } = useDisplay();

const DELAY_MS_ON_OPEN = 500;
const DELAY_MS_ON_CLOSE = 1000;

watch(show_menu, (newValue) => {
  if (newValue === true) {
    show_background.value = true;
    showMenuModalNoDelay.value = true;
    setTimeout(() => {
      show_menu_modal.value = true;
    }, DELAY_MS_ON_OPEN);
  } else {
    const line = document.getElementById("magic").classList;
    line.remove("grow-magic");
    line.add("reduce-magic");

    const arrayList = Array.from(document.getElementsByClassName("grows")); // replacing in BurgerMenu animation for close nav-list
    for (let i = 0; i < arrayList.length; i++) {
      if (arrayList[i].classList.contains("grows")) {
        arrayList[i].classList.remove("grows");
      }
      arrayList[i].classList.add("reduce");
    }

    const modal = document.getElementById("modal").classList; // replacing in BackgroundView animation from scale to shrink
    // const email = document.getElementById("email").classList;
    showMenuModalNoDelay.value = false;
    setTimeout(() => {
      modal.remove("scale");
      modal.add("shrink");

      setTimeout(() => {
        show_menu_modal.value = false;
        show_background.value = false;
      }, 500);
    }, DELAY_MS_ON_CLOSE);
  }
});

const goToHome = () => {
  setTimeout(() => scrollTo("A", 0), 100);
};

const scrollTo = (el, offset) => {
  let ref = document.getElementById(el);
  window.scroll({ top: ref.offsetTop - offset, left: 0, behavior: "smooth" });
};
</script>

<style scoped>
.burger_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  cursor: pointer;
  gap: 6px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.burger_wrapper::before {
  content: "";
  height: 4px;
  width: 60px;
  background-color: red;
}

.burger_wrapper::after {
  content: "";
  height: 4px;
  width: 60px;
  background-color: red;
}

.burger-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  height: 60px;
  width: 60px;
  cursor: pointer;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
}

.b-before,
.b-after {
  transform-origin: right;
  height: 4px;
  width: 60px;
  background-color: #dc012e;
  transform: scaleX(1);
}

.burger-enter-from .b-before,
.burger-enter-from .b-after {
  transform: scaleX(0);
}

.burger-enter-to .b-before,
.burger-enter-to .b-after {
  transform: scaleX(1);
}

.burger-leave-from .b-before,
.burger-leave-from .b-after {
  transform: scaleX(1);
}

.burger-leave-to .b-before,
.burger-leave-to .b-after {
  transform: scaleX(0);
}

.burger-enter-active .b-before {
  transform-origin: left;
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;
}

.burger-enter-active .b-after {
  transform-origin: left;
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-leave-active .b-before {
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-leave-active .b-after {
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;
}

.close-icon {
  display: flex;
  transform: rotate(45deg);
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  cursor: pointer;
}
.c-before {
  transform-origin: top;
  transform: scale(1);
  position: absolute;
  background-color: #dc012e;
  height: 50px;
  width: 4px;
}
.c-after {
  transform-origin: right;
  transform: scale(1);
  position: absolute;
  background-color: #dc012e;
  height: 4px;
  width: 50px;
}

.burger-enter-from .c-before {
  transform: scaleY(0);
  transform-origin: bottom;
}

.burger-enter-from .c-after {
  transform: scaleX(0);
  transform-origin: left;
}

.burger-enter-to .c-before {
  transform-origin: bottom;
  transform: scaleY(1);
}
.burger-enter-to .c-after {
  transform: scaleX(1);
  transform-origin: left;
}

.burger-leave-from .c-before {
  transform-origin: top;
  transform: scaleY(1);
}

.burger-leave-from .c-after {
  transform: scaleX(1);
  transform-origin: right;
}

.burger-leave-to .c-before {
  transform-origin: top;
  transform: scaleY(0);
}
.burger-leave-to .c-after {
  transform-origin: right;
  transform: scaleX(0);
}

.burger-enter-active .c-before {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-enter-active .c-after {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 100ms;
}

.burger-leave-active .c-before {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 400ms;
}

.burger-leave-active .c-after {
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 500ms;
}
</style>

import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

// Vuetify
import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
// import { ru } from "vuetify/locale";
// import { VDateInput, VNumberInput } from "vuetify/labs/components";
// import { VFab } from "vuetify/lib/components/VFab";

const LightTheme = {
  dark: false,
  colors: {
    background: "#EBECE7",
    surface: "#ffffff",
    // "surface-bright": "#FFFFFF",
    "surface-light": "#FFFFFF",
    // "surface-variant": "#424242",
    // "on-surface-variant": "#EEEEEE",
    primary: "#DC012E",
    secondary: "#8A92FB",
    accent: "#AEEA00",
    error: "#ff2d55",
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FB8C00',
  },
  variables: {
    // "border-color": "#9d0000",
    // 'border-opacity': 0.12,
  },
};

export default createVuetify({
  components: {
    // VNumberInput,
    // VDateInput,
    // VFab,
  },
  defaults: {
    // VCombobox: { density: "compact" },
    // VAutocomplete: {
    //   density: "compact",
    // },
    // VSelect: {
    //   density: "compact",
    // },
    // VTextField: {
    //   variant: "outlined",
    //   density: "compact",
    // },
    // VCard: {
    //   rounded: "lg",
    // },
    // VBtn: {
    //   rounded: "lg",
    // },
    VCol: {
      style: "padding: 0 0 0 0;",
    },
  },
  icons: {
    defaultSet: "mdi",
  },
  theme: {
    defaultTheme: "LightTheme",
    themes: {
      LightTheme,
    },
  },
  locale: {
    // locale: "ru",
    // fallback: "ru",
    // messages: { ru },
  },
  //this is for global translations
});

<template>
  <transition name="back">
    <div v-if="show_menu" class="background"></div>
  </transition>
</template>

<script setup>
defineOptions({ name: "MenuBackground" });
const show_menu = defineModel("showMenu", { type: Boolean, default: false });
</script>

<style scoped>
.background {
  will-change: transform;
  position: fixed;
  top: calc(3vw + 25px);
  right: calc(5vw + 24px);
  height: calc((400vw + 400vh) / 2);
  width: calc((400vw + 400vh) / 2);
  background-color: var(--h-global-color-menu-background);
  border-radius: 50%;
  transform: translate(50%, -50%) scale(1);
  z-index: 1900;
}

.back-enter-from {
  transform: translate(50%, -50%) scale(0);
}

.back-leave-to {
  transform: translate(50%, -50%) scale(0);
}

.back-enter-active {
  transition: transform 600ms ease-in-out;
}
.back-leave-active {
  transition: transform 600ms ease-in-out 600ms;
}
</style>

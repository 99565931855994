<template>
  <v-row
    no-gutters
    class="flex-column justify-center align-center align-sm-center"
    :style="{ height: getBodyHeight + 'px' }"
    id="A"
  >
    <v-col
      class="d-none d-sm-flex flex-column mt-auto"
      style="min-height: 300px; flex: 0 0 auto; margin-bottom: 8vh"
    >
      <p v-if="showLines[0]" class="font-weight-medium text-h4 text-lg-h3">
        {{ lines[0] }}
      </p>
      <p
        v-if="showLines[1]"
        class="font-weight-black font-italic text-h4 text-lg-h3"
      >
        {{ lines[1] }}
      </p>
      <p v-if="showLines[2]" class="font-weight-medium text-h4 text-lg-h3">
        {{ lines[2] }}
      </p>
      <p v-if="showLines[3]" class="font-weight-medium text-h4 text-lg-h3">
        {{ lines[3] }}
      </p>
    </v-col>

    <!--                FOR MOBILE     -->

    <v-col
      class="d-block d-sm-none mt-auto"
      style="min-height: 360px; flex: 0 0 auto; margin-bottom: 8vh"
    >
      <p
        v-if="showLinesMobile[0]"
        class="font-weight-medium text-h5 height-less"
        style="z-index: 2000 !important"
      >
        {{ linesMobile[0] }}
      </p>
      <p
        v-if="showLinesMobile[1]"
        class="font-weight-medium text-h5 height-less"
      >
        {{ linesMobile[1] }}
      </p>
      <v-row class="d-flex" no-gutters>
        <v-col
          v-if="showLinesMobile[2]"
          class="font-weight-medium text-h5 height-less"
          style="flex-grow: 0"
        >
          {{ linesMobile[2] }}
        </v-col>
        &nbsp;
        <v-col
          v-if="showLinesMobile[3]"
          class="font-weight-black font-italic text-h5 height-less"
          style="flex-grow: 0"
        >
          {{ linesMobile[3] }}
        </v-col>
      </v-row>

      <p
        v-if="showLinesMobile[4]"
        class="font-weight-black font-italic text-h5 height-less"
      >
        {{ linesMobile[4] }}
      </p>
      <p
        v-if="showLinesMobile[5]"
        class="font-weight-medium text-h5 height-less"
      >
        {{ linesMobile[5] }}
      </p>
      <p
        v-if="showLinesMobile[6]"
        class="font-weight-medium text-h5 height-less"
      >
        {{ linesMobile[6] }}
      </p>
      <p
        v-if="showLinesMobile[7]"
        class="font-weight-medium text-h5 height-less"
      >
        {{ linesMobile[7] }}
      </p>
      <p
        v-if="showLinesMobile[8]"
        class="font-weight-medium text-h5 height-less"
      >
        {{ linesMobile[8] }}
      </p>
    </v-col>
    <!--    <div class="me-auto text-body-2 text-sm-h6 align-content-end">-->
    <!-- -->
    <!--    </div>-->
  </v-row>
  <div
    class="me-auto me-sm-auto text-caption text-sm-body-2"
    style="height: 10vh"
  >
    &#169; 2024 YMC Partners Limited. All rights reserved.
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useDisplay } from "vuetify";

defineOptions({ name: "HomeView" });

const text =
  "We redefine financial landscapes by. structuring transformative deals. and unlocking strategic opportunities. for our elite clientele.";
const textMobile =
  "We redefine. financial landscapes. by. structuring. transformative deals. and unlocking. strategic. opportunities. for our elite clientele.";
const lines = ref(["", "", "", ""]);
const linesMobile = ref(["", "", "", "", "", "", "", "", ""]);
const showLines = ref([false, false, false, false]);
const showLinesMobile = ref([
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
]);
const typingSpeed = 75;

const typeText = (line, index) => {
  return new Promise((resolve) => {
    let charIndex = 0;
    showLines.value[index] = true;
    const interval = setInterval(() => {
      if (charIndex < line.length) {
        lines.value[index] += line[charIndex++];
      } else {
        clearInterval(interval);
        resolve();
      }
    }, typingSpeed);
  });
};

const typeTextMobile = (line, index) => {
  return new Promise((resolve) => {
    let charIndex = 0;
    showLinesMobile.value[index] = true;
    const interval = setInterval(() => {
      if (charIndex < line.length) {
        linesMobile.value[index] += line[charIndex++];
      } else {
        clearInterval(interval);
        resolve();
      }
    }, typingSpeed);
  });
};

const startTyping = async () => {
  const linesArray = text.split(". ");
  for (let i = 0; i < linesArray.length; i++) {
    await typeText(linesArray[i], i);
  }
};

const startTypingMobile = async () => {
  const linesArrayMobile = textMobile.split(". ");
  for (let i = 0; i < linesArrayMobile.length; i++) {
    await typeTextMobile(linesArrayMobile[i], i);
  }
};

const { name, height } = useDisplay();

const getBodyHeight = computed(() => {
  let result = 0;
  switch (name.value) {
    case "xs":
      result = height.value * 0.9 - 100;
      console.log("Height", height);
      break;
    case "sm":
      result = height.value * 0.9 - 100;
      console.log("Height", height);
      break;
    case "md":
      result = height.value * 0.9 - 200;
      console.log("Height", height);
      break;
    case "lg":
      result = height.value * 0.9 - 200;
      break;
    case "xl":
      result = height.value * 0.9 - 200;
      break;
    case "xxl":
      result = height.value * 0.9 - 200;
      break;
  }
  return result;
});

onMounted(() => {
  // typingText();
  startTypingMobile();
  startTyping();
});
</script>

<style scoped></style>

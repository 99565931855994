import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import store from "./store";
import "@/assets/styles/main.css";
import VueTyped from "vue3-typed-js";

loadFonts();

const app = createApp(App);
app.use(router).use(store).use(vuetify).use(VueTyped).mount("#app");

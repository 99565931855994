<template>
  <!--  <v-row-->
  <!--    no-gutters-->
  <!--    class="align-start"-->
  <!--    style="background-color: coral; height: 100vh; width: 100vw"-->
  <!--    id="C"-->
  <!--  >-->
  <!--    <v-col class="text-h5 text-sm-h4 text-lg-h3">-->
  <!--      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod-->
  <!--      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim-->
  <!--      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea-->
  <!--      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate-->
  <!--      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat-->
  <!--      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id-->
  <!--      est laborum.-->
  <!--    </v-col>-->
  <!--  </v-row>-->

  <v-row
    no-gutters
    id="C"
    class="pt-10"
    style="min-height: 100vh; margin-top: 200px"
  >
    <v-col cols="12" md="12">
      <v-row no-gutters align="end" id="our_people">
        <v-row no-gutters justify="space-between" align="end">
          <v-col
            cols="12"
            md="4"
            class="text-h5 text-md-h4 text-lg-h3 font-weight-medium mb-4 mb-md-0"
            style="color: var(--h-global-color-text)"
          >
            An elite team.
            <br />
            <span class="ma-0 font-weight-semi-bold font-italic">
              Unrivaled
            </span>
            expertise.
          </v-col>
          <v-col
            cols="10"
            md="7"
            class="text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 line-h-people text-md-h6"
          >
            Our team is comprised of industry leaders with a wealth of
            experience in finance, deal structuring, Web3 and strategic
            advisory. We are committed to delivering innovative and excellent
            results.
          </v-col>
        </v-row>
      </v-row>
      <v-divider class="mt-7 mt-md-8"></v-divider>

      <v-row no-gutters align="center" class="my-2 my-md-6">
        <v-col
          class="text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 font-weight-bold text-uppercase"
        >
          OUR PEOPLE
        </v-col>
      </v-row>
      <v-row no-gutters justify="space-between" class="pe-12 mt-6 mt-md-0">
        <v-col cols="10" sm="3" class="mb-10 mb-md-6" style="flex-grow: 0">
          <v-responsive :aspect-ratio="1">
            <v-card class="w-100" rounded="xl" flat>
              <v-img :src="yai" :aspect-ratio="1" cover></v-img>
            </v-card>
          </v-responsive>
          <v-col
            class="text-white text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 text-high-emphasis font-weight-bold mt-md-3 text-no-wrap"
          >
            <a
              class="text-black font-weight-bold text-decoration-none d-inline-block"
              href="https://www.linkedin.com/in/yai-sukonthabhund" 
              target="_blank"
            >
              Yai Sukonthabhund
            </a>
            <a
              href="https://www.linkedin.com/in/yai-sukonthabhund"
              class="none text-black cursor-pointer"
              target="_blank"
            >
              <v-icon
                :size="getIconSize"
                icon="mdi-linkedin"
                class="ms-1 cursor-pointer"
                style="padding-bottom: 5px"
              ></v-icon>
            </a>
            <br />
            <span
              class="text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 font-weight-regular font-italic"
            >
              Partner
            </span>
          </v-col>
        </v-col>

        <v-col cols="10" sm="3" l class="mb-10 mb-md-6" style="flex-grow: 0">
          <v-responsive :aspect-ratio="1">
            <v-card class="w-100" rounded="xl" flat>
              <v-img :src="moe" :aspect-ratio="1" cover></v-img>
            </v-card>
          </v-responsive>
          <v-col
            class="text-white text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 text-high-emphasis font-weight-bold mt-md-3 text-no-wrap"
          >
            <a
              class="text-black font-weight-bold text-decoration-none d-inline-block"
              href="https://www.linkedin.com/in/moeibrahim"
              target="_blank"
            >
              Moe Ibrahim
            </a>
            <a
              href="https://www.linkedin.com/in/moeibrahim"
              class="none text-black cursor-pointer"
              target="_blank"
            >
              <v-icon
                :size="getIconSize"
                icon="mdi-linkedin"
                class="ms-1"
                style="padding-bottom: 5px"
              ></v-icon>
            </a>
            <br />

            <span
              class="text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 font-weight-regular font-italic"
            >
              Partner
            </span>
          </v-col>
        </v-col>

        <v-col cols="10" sm="3" class="mb-2 mb-md-6" style="flex-grow: 0">
          <v-responsive :aspect-ratio="1">
            <v-card class="w-100" rounded="xl" flat>
              <v-img :src="clement" :aspect-ratio="1" cover></v-img>
            </v-card>
          </v-responsive>
          <v-col
            class="text-white text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 text-high-emphasis font-weight-bold mt-md-3 text-no-wrap"
          >
            <a
              class="text-black font-weight-bold text-decoration-none d-inline-block"
              href="https://www.linkedin.com/in/clemwong"
              target="_blank"
            >
              Clement Wong
            </a>
            <a
              href="https://www.linkedin.com/in/clemwong"
              class="none text-black cursor-pointer"
              target="_blank"
            >
              <v-icon
                :size="getIconSize"
                icon="mdi-linkedin"
                class="ms-1"
                style="padding-bottom: 5px"
              ></v-icon>
            </a>
            <br />
            <span
              class="text-subtitle-2 text-md-h6 text-lg-h5 text-xl-h4 font-weight-regular font-italic"
            >
              Partner
            </span>
          </v-col>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import yai from "@/assets/photos/yai.jpeg";
import moe from "@/assets/photos/moe.jpeg";
import clement from "@/assets/photos/clemet.jpeg";
import { useDisplay } from "vuetify";
import { computed } from "vue";

defineOptions({ name: "OurPeopleView" });

const { name } = useDisplay();

const getIconSize = computed(() => {
  let result = 0;
  switch (name.value) {
    case "xs":
      result = 25;
      break;
    case "sm":
      result = 30;
      break;
    case "md":
      result = 30;
      break;
    case "lg":
      result = 30;
      break;
    case "xl":
      result = 50;
      break;
    case "xxl":
      result = 50;
      break;
  }
  return result;
});
</script>

<style scoped>
a {
  position: relative;
}

a::before {
  content: "";

  position: absolute;
  left: 0;
  bottom: -0.2em;
  width: 100%;
  height: 2px;
  background: rgba(0, 0, 0, 0.27) !important;
  margin-bottom: 5px;
}

a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.2em;
  margin-bottom: 5px;
  width: 100%;
  height: 2px;
  background: #000000de !important;
  transform: scaleX(0);
}
a:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}
a:not(hover)::after {
  transform-origin: right;
  transform: scaleX(0);
}

.none::before,
.none::after {
  content: none;
}

a::after,
a::before {
  transform-origin: right;
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
</style>

<template>
  <v-app-bar color="transparent" :height="getTopPadding" flat>
    <transition name="appbar" :duration="500">
      <div
        v-if="!show_menu"
        class="position-fixed top-0 left-0 h-100 w-100 appbar-bg"
        style="z-index: -1"
      ></div>
    </transition>
    <MainHeader
      :is-menu-opened-instant="showMenuNoDelay"
      @close-menu-dialog="closeMenuDialog"
      @open-menu-dialog="openMenuDialog"
    ></MainHeader>
  </v-app-bar>
  <v-main>
    <v-container class="py-0">
      <v-layout class="flex-column" @mousemove="provideYCoordinate">
        <HomeView></HomeView>

        <OurMissionView></OurMissionView>

        <OurPeopleView></OurPeopleView>

        <ContactView></ContactView>
        <BurgerMenu :show="show_menu" @on-close="closeMenuDialog"></BurgerMenu>
        <MenuBackground v-model:show-menu="show_menu"></MenuBackground>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script setup>
import { useDisplay } from "vuetify";
import { computed, provide, ref } from "vue";
import MainHeader from "@/views/MainView/MainHeader/index.vue";
import HomeView from "@/views/MainView/HomeView/index.vue";
import OurMissionView from "@/views/MainView/OurMissionView/index.vue";
import OurPeopleView from "@/views/MainView/OurPeopleView/index.vue";
import ContactView from "@/views/MainView/ContactView/index.vue";
import BurgerMenu from "@/views/MainView/BurgerMenu/index.vue";
import MenuBackground from "@/views/MainView/BurgerMenu/MenuBackground/index.vue";

defineOptions({ name: "MainView" });

const show_menu = ref(false);
const showMenuNoDelay = ref(false);

const mainViewY = ref(null);
const { name } = useDisplay();

const getTopPadding = computed(() => {
  let result = 0;
  switch (name.value) {
    case "xs":
      result = 110;
      break;
    case "sm":
      result = 110;
      break;
    case "md":
      result = 200;
      break;
    case "lg":
      result = 200;
      break;
    case "xl":
      result = 200;
      break;
    case "xxl":
      result = 200;
      break;
  }
  return result;
});

provide("MainViewYCoordinate", mainViewY);

const provideYCoordinate = (e) => {
  mainViewY.value = e.pageY;
};

const openMenuDialog = () => {
  show_menu.value = true;
  showMenuNoDelay.value = true;
};

const closeMenuDialog = () => {
  show_menu.value = false;
  showMenuNoDelay.value = false;
};
</script>

<style scoped>
.appbar-bg {
  background-color: #ebece7;
  transition: background-color 400ms ease-in-out 500ms;
}
.appbar-enter-from {
  background-color: transparent;
}
.appbar-enter-to {
  background-color: #ebece7;
}
.appbar-leave-from {
  background-color: #ebece7;
}
.appbar-leave-to {
  background-color: transparent;
}
.appbar-enter-active {
  transition: background-color 100ms ease-in-out 1000ms;
}
.appbar-leave-active {
  transition: background-color 100ms ease-in-out;
}
</style>
